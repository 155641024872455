@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  width: 100% !important;
  border-radius: 20px;
  box-sizing: border-box;
  border-radius: 20px !important;
  height: 50px;
  background-color: #ffffff00 !important; }
  .card span {
    font-size: 0.57rem;
    color: #1d77d3;
    margin: 2px; }
  .card b {
    color: #1d77d3 !important; }
  .card img {
    margin: 5px; }

.results {
  position: absolute !important;
  width: 100%;
  background-color: white !important;
  box-sizing: border-box !important;
  z-index: 2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-top: -24px;
  padding: 50px 20px 26px 20px;
  box-shadow: 0 0 1px black; }
  .results .results-list {
    overflow-y: auto;
    max-height: 300px;
    font-family: "Comfortaa", cursive;
    color: #1d77d3;
    font-size: 0.75rem;
    width: 100% !important;
    padding: 2px; }
  .results b {
    font-size: 0.65rem;
    color: gray;
    text-transform: uppercase; }
  .results li {
    padding: 0;
    width: 97% !important;
    border-radius: 20px;
    height: 50px;
    margin-bottom: 10px; }
    .results li:hover {
      cursor: pointer; }
  .results li:nth-child(even) {
    background-color: #deedfc; }
    .results li:nth-child(even) .avatar {
      background-color: #fff !important; }
  .results li:nth-child(odd) {
    background-color: #fff; }
    .results li:nth-child(odd) .avatar {
      background-color: #fff !important; }

.MuiCardHeader-root {
  padding: 7px !important; }

.avatar {
  width: 30px !important;
  height: 30px !important;
  border: 1px solid #deedfc; }

.video-call {
  width: 30px !important;
  height: 30px !important;
  background-color: #1d77d3;
  float: right;
  border-radius: 100%;
  margin: 7px 20px 0px 0px; }
  .video-call img {
    margin: 5px; }

.txt-video-call {
  margin: 0;
  font-size: 0.5rem;
  color: #1d77d3;
  margin-right: 14px; }

.MuiButton-label {
  color: #2a7fd5;
  font-size: 0.75rem; }

.clear-search {
  position: relative;
  z-index: 10;
  float: right; }

@media (max-width: 575.98px) {
  .card {
    height: 50px;
    background-color: #ffffff00 !important; }
    .card span {
      font-size: 0.57rem;
      color: #1d77d3;
      margin: 2px; }
    .card b {
      color: #1d77d3 !important; }
    .card img {
      margin: 5px; }
  .results {
    width: 100%;
    margin-top: -24px;
    padding: 50px 20px 26px 20px; }
    .results .results-list {
      max-height: 300px;
      font-size: 0.75rem;
      width: 100% !important; }
    .results b {
      font-size: 0.45rem; }
    .results li {
      padding: 0;
      width: 97% !important;
      border-radius: 15px;
      height: 50px;
      margin-bottom: 10px;
      margin-right: 5px; }
  .MuiCardHeader-root {
    padding: 7px !important; }
  .avatar {
    width: 30px !important;
    background-color: #deedfc !important;
    height: 30px !important; }
  .video-call {
    width: 30px !important;
    height: 30px !important;
    margin: 7px 20px 0px 0px; }
    .video-call img {
      margin: 5px; }
  .txt-video-call {
    margin: 0;
    font-size: 0.5rem;
    margin-right: 14px; }
  .MuiButton-label {
    font-size: 0.65rem; }
  .clear-search {
    font-size: 0.5rem !important; } }

.suggestion {
  position: absolute !important;
  width: 100%;
  background-color: white !important;
  box-sizing: border-box !important;
  z-index: 2;
  border-radius: 30px;
  margin-top: -24px;
  padding: 50px 20px 26px 20px;
  box-shadow: 0 0 1px black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px; }
  .suggestion .suggests-list {
    font-family: "Comfortaa", cursive;
    color: #1d77d3;
    font-size: 0.75rem;
    overflow-y: auto;
    max-height: 300px;
    width: 100%; }
  .suggestion b {
    font-size: 0.75rem;
    color: gray;
    text-transform: uppercase; }
  .suggestion li {
    padding-left: 0; }
    .suggestion li:hover {
      cursor: pointer; }

.MuiButton-label {
  color: #1d77d3;
  font-size: 0.75rem; }

.clear-search {
  float: right; }

.clear-search-div {
  width: 100%;
  height: 25px; }

@media (max-width: 575.98px) {
  .suggestion {
    width: 100%;
    margin-top: -24px;
    padding: 50px 20px 26px 20px; }
    .suggestion .suggests-list {
      font-size: 0.75rem;
      max-height: 230px;
      width: 100%; }
    .suggestion b {
      font-size: 0.55rem; }
    .suggestion li {
      padding-left: 0; }
  .MuiButton-label {
    color: #1d77d3;
    font-size: 0.65rem !important; }
  .clear-search {
    float: right;
    padding-top: 0 !important; }
  .clear-search-div {
    width: 100%;
    height: 25px; } }

div[role="progressbar"] {
  margin: 8px !important; }

.search {
  position: relative; }

.paper-card {
  padding: 4px 15px;
  display: flex;
  align-items: center;
  border-radius: 30px !important;
  box-shadow: 0px 4px 2px 0px #DEEDFC !important;
  border: 1px solid #DEEDFC !important;
  z-index: 3 !important;
  width: 100%;
  box-sizing: border-box !important;
  position: relative;
  color: gray; }

.text-main-page {
  text-align: center;
  font-family: 'Comfortaa', cursive;
  color: #587399;
  z-index: 1;
  padding: 10px;
  position: absolute;
  top: 70px;
  left: 0; }

.search-logo {
  width: 25px;
  opacity: 0.4; }

.search-input {
  margin-left: spacing(1);
  flex: 1 1;
  color: gray !important; }

.message {
  padding-left: 40px;
  color: #db7676; }

@media (max-width: 575.98px) {
  .paper-card {
    padding: 4px 15px;
    width: 100%;
    height: 35px; }
  .text-main-page {
    padding: 10px;
    top: 40px;
    left: 0; }
  .search-logo {
    width: 20px; }
  .search-input {
    margin-left: spacing(1);
    font-size: 0.75rem !important; }
  .message {
    padding-left: 40px; } }

.QR-code {
  height: 100px;
  width: 100px;
  text-align: center;
  box-shadow: 0 0 3px 0px #587399;
  font-size: 1rem;
  margin: 10px;
  border-radius: 20px;
  padding: 10px; }
  .QR-code:hover {
    cursor: pointer; }
  .QR-code .qrcode-icon {
    margin: 20px;
    opacity: 0.8; }

.QR-codes {
  position: relative; }

.QR-code-page {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0 0 1px black;
  margin: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-top: -30px;
  padding: 50px 20px;
  overflow-y: auto;
  font-family: "Comfortaa", cursive;
  color: #848497;
  font-size: 0.95rem;
  text-align: center; }
  .QR-code-page h4 {
    margin: 25px 0px 0px 0px;
    color: #587399;
    text-transform: capitalize;
    font-family: "Comfortaa", cursive; }
  .QR-code-page p {
    margin: 7px 0px 25px 0px; }
  .QR-code-page li {
    padding: 0;
    width: 100% !important;
    border-radius: 20px; }

b,
a {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  color: #1D77D3;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-family: "Comfortaa", cursive; }

.MuiButton-label {
  color: #1D77D3;
  font-size: 0.75rem; }

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c3 {
    color: #595959;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11.5pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c24 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal
}

.c18 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    /* orphans: 2;
    widows: 2; */
    text-align: left
}

.c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c11 {
    background-color: #ffffff;
    font-size: 10pt;
    font-family: "Arial";
    color: #595959;
    font-weight: 700
}

.c21 {
    background-color: #ffffff;
    font-size: 13.5pt;
    font-family: "Arial";
    color: #000000;
    font-weight: 700
}

.c2 {
    font-family: "Arial";
    font-weight: 700
}

.c7 {
    background-color: #ffffff;
    font-size: 11.5pt;
    font-family: "Arial";
    color: #595959;
    font-weight: 400
}

.c22 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none
}

.c5 {
    font-size: 11.5pt;
    font-family: "Times New Roman";
    color: #595959;
    font-weight: 700
}

.c4 {
    font-size: 11.5pt;
    font-family: "Times New Roman";
    color: #595959;
    font-weight: 400
}

.c12 {
    background-color: #ffffff;
    font-family: "Arial";
    font-weight: 400
}

.c16 {
    font-family: "Times New Roman";
    color: #7f7f7f;
    font-weight: 700
}

.c19 {
    background-color: #ffffff;
    max-width: 451pt;
    padding: 72pt 72pt 72pt 72pt
}

.c15 {
    font-size: 19.5pt;
    font-family: "Times New Roman";
    font-weight: 700
}

.c23 {
    color: inherit;
    text-decoration: inherit
}

.c8 {
    font-weight: 400;
    font-family: "Times New Roman"
}

.c9 {
    font-size: 11.5pt;
    color: #000000
}

.c17 {
    font-weight: 700;
    font-family: "Times New Roman"
}

.c14 {
    font-size: 11.5pt
}

.c13 {
    font-style: italic
}

.c6 {
    height: 12pt
}

.c20 {
    color: #595959
}

.c10 {
    background-color: #ffff00
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.lst-kix_list_2-6>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-7>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_2-4>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: "o  "
}

.lst-kix_list_1-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-4>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-7>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-6>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_2-1>li:before {
    content: "o  "
}

.lst-kix_list_1-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-3>li:before {
    content: "\0025aa  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c0 {
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c8 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c13 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal
}

.c5 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    font-family: "Times New Roman"
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c12 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-style: normal
}

.c10 {
    background-color: transparent;
    max-width: 451pt;
    padding: 72pt 72pt 72pt 72pt
}

.c6 {
    font-weight: 700;
    font-family: "Times New Roman"
}

.c11 {
    padding: 0;
    margin: 0
}

.c7 {
    color: inherit;
    text-decoration: inherit
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    /* color: #666666; */
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 0pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.App {
  min-height: 100vh;
  font-family: "Comfortaa", cursive;
  background-image: url("/static/panel/img/page_bg.jpg");
  background-size: cover;
  background-attachment: fixed; }

.content {
  font-size: 1rem;
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.logo {
  margin: 50px;
  text-align: center; }
  .logo img {
    width: 140px; }

.logo-other-pages {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center; }

p,
b:hover {
  cursor: default; }

@media (max-width: 767.98px) {
  .content {
    font-size: 0.75rem; }
  .logo {
    margin: 75px;
    margin-bottom: 25px; }
    .logo img {
      width: 90px; } }

