.App {
  min-height: 100vh;
  font-family: "Comfortaa", cursive;
  background-image: url("/static/panel/img/page_bg.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.content {
  font-size: 1rem;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  margin: 50px;
  text-align: center;

  img {
    width: 140px;
  }
}

.logo-other-pages {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

p,
b:hover {
  cursor: default;
}

@media (max-width: 767.98px) {
  .content {
    font-size: 0.75rem;
  }

  .logo {
    margin: 75px;
    margin-bottom: 25px;

    img {
      width: 90px;
    }
  }
}
