.search{
    position: relative;
}
.paper-card {
    padding: 4px 15px;
    display: flex;
    align-items: center;
    border-radius: 30px !important;
    box-shadow: 0px 4px 2px 0px #DEEDFC !important;
    border: 1px solid #DEEDFC !important;
    z-index: 3!important;
    width: 100%;
    box-sizing: border-box!important;
    position: relative;
    color: gray;
}
.text-main-page{
    text-align: center;
    font-family: 'Comfortaa', cursive;
    color: #587399;
    z-index: 1;
    padding: 10px;
    position: absolute;
    top:70px;
    left: 0;
}
.search-logo{
    width: 25px;
    opacity: 0.4;
}
.search-input{
    margin-left:spacing(1);
    flex: 1;
    color: gray!important;
    // z-index: 1000;
}
.message{
    padding-left: 40px;
    color: rgb(219, 118, 118);
}
@media (max-width: 575.98px) {
    .paper-card {
        padding: 4px 15px;
        width: 100%;
        height: 35px;
    }
    .text-main-page{
        padding: 10px;
        top:40px;
        left: 0;
    }
    .search-logo{
        width: 20px;
    }
    .search-input{
        margin-left:spacing(1);
        font-size: 0.75rem!important;
    }
    .message{
        padding-left: 40px;
    }

}