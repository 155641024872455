@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c3 {
    color: #595959;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11.5pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c24 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal
}

.c18 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    /* orphans: 2;
    widows: 2; */
    text-align: left
}

.c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c11 {
    background-color: #ffffff;
    font-size: 10pt;
    font-family: "Arial";
    color: #595959;
    font-weight: 700
}

.c21 {
    background-color: #ffffff;
    font-size: 13.5pt;
    font-family: "Arial";
    color: #000000;
    font-weight: 700
}

.c2 {
    font-family: "Arial";
    font-weight: 700
}

.c7 {
    background-color: #ffffff;
    font-size: 11.5pt;
    font-family: "Arial";
    color: #595959;
    font-weight: 400
}

.c22 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.c5 {
    font-size: 11.5pt;
    font-family: "Times New Roman";
    color: #595959;
    font-weight: 700
}

.c4 {
    font-size: 11.5pt;
    font-family: "Times New Roman";
    color: #595959;
    font-weight: 400
}

.c12 {
    background-color: #ffffff;
    font-family: "Arial";
    font-weight: 400
}

.c16 {
    font-family: "Times New Roman";
    color: #7f7f7f;
    font-weight: 700
}

.c19 {
    background-color: #ffffff;
    max-width: 451pt;
    padding: 72pt 72pt 72pt 72pt
}

.c15 {
    font-size: 19.5pt;
    font-family: "Times New Roman";
    font-weight: 700
}

.c23 {
    color: inherit;
    text-decoration: inherit
}

.c8 {
    font-weight: 400;
    font-family: "Times New Roman"
}

.c9 {
    font-size: 11.5pt;
    color: #000000
}

.c17 {
    font-weight: 700;
    font-family: "Times New Roman"
}

.c14 {
    font-size: 11.5pt
}

.c13 {
    font-style: italic
}

.c6 {
    height: 12pt
}

.c20 {
    color: #595959
}

.c10 {
    background-color: #ffff00
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}
