.card {
  width: 100% !important;
  border-radius: 20px;
  box-sizing: border-box;
  border-radius: 20px !important;
  height: 50px;
  background-color: #ffffff00 !important;
  span {
    font-size: 0.57rem;
    color: #1d77d3;
    margin: 2px;
  }
  b {
    color: #1d77d3 !important;
  }
  img {
    margin: 5px;
  }
}

.results {
  position: absolute !important;
  width: 100%;
  background-color: white !important;
  box-sizing: border-box !important;
  z-index: 2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-top: -24px;
  padding: 50px 20px 26px 20px;
  box-shadow: 0 0 1px black;
  .results-list {
    overflow-y: auto;
    max-height: 300px;
    font-family: "Comfortaa", cursive;
    color: #1d77d3;
    font-size: 0.75rem;
    width: 100% !important;
    padding: 2px;
  }
  b {
    font-size: 0.65rem;
    color: gray;
    text-transform: uppercase;
  }
  li {
    padding: 0;
    width: 97% !important;
    border-radius: 20px;
    height: 50px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  li:nth-child(even) {
    background-color: #deedfc;
    .avatar {
      background-color: #fff !important;
    }
  }
  li:nth-child(odd) {
    background-color: #fff;
    .avatar {
      background-color: #fff !important;
    }
  }
}
.MuiCardHeader-root {
  padding: 7px !important;
}
.avatar {
  width: 30px !important;
  // background-color: #DEEDFC!important;
  height: 30px !important;
  border: 1px solid #deedfc;
}
.video-call {
  width: 30px !important;
  height: 30px !important;
  background-color: #1d77d3;
  float: right;
  border-radius: 100%;
  margin: 7px 20px 0px 0px;
  img {
    margin: 5px;
  }
}
.txt-video-call {
  margin: 0;
  font-size: 0.5rem;
  color: #1d77d3;
  margin-right: 14px;
}
.MuiButton-label {
  color: #2a7fd5;
  font-size: 0.75rem;
}

.clear-search {
  position: relative;
  z-index: 10;
  float: right;
}
@media (max-width: 575.98px) {
  .card {
    height: 50px;
    background-color: #ffffff00 !important;
    span {
      font-size: 0.57rem;
      color: #1d77d3;
      margin: 2px;
    }
    b {
      color: #1d77d3 !important;
    }
    img {
      margin: 5px;
    }
  }

  .results {
    width: 100%;
    margin-top: -24px;
    padding: 50px 20px 26px 20px;
    .results-list {
      max-height: 300px;

      font-size: 0.75rem;
      width: 100% !important;
    }
    b {
      font-size: 0.45rem;
    }
    li {
      padding: 0;
      width: 97% !important;
      border-radius: 15px;
      height: 50px;
      margin-bottom: 10px;
      margin-right: 5px;
    }
  }
  .MuiCardHeader-root {
    padding: 7px !important;
  }
  .avatar {
    width: 30px !important;
    background-color: #deedfc !important;
    height: 30px !important;
  }
  .video-call {
    width: 30px !important;
    height: 30px !important;
    margin: 7px 20px 0px 0px;
    img {
      margin: 5px;
    }
  }
  .txt-video-call {
    margin: 0;
    font-size: 0.5rem;
    margin-right: 14px;
  }
  .MuiButton-label {
    font-size: 0.65rem;
  }
  .clear-search {
    font-size: 0.5rem !important;
  }
}
