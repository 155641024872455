@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');

.lst-kix_list_2-6>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-7>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_2-4>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-8>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf  "
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1>li:before {
    content: "o  "
}

.lst-kix_list_1-2>li:before {
    content: "\0025aa  "
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-4>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-7>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-5>li:before {
    content: "\0025aa  "
}

.lst-kix_list_1-6>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf  "
}

.lst-kix_list_2-1>li:before {
    content: "o  "
}

.lst-kix_list_1-8>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-2>li:before {
    content: "\0025aa  "
}

.lst-kix_list_2-3>li:before {
    content: "\0025aa  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c0 {
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c8 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c13 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal
}

.c5 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration-skip-ink: none;
    font-family: "Times New Roman"
}

.c9 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c12 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-style: normal
}

.c10 {
    background-color: transparent;
    max-width: 451pt;
    padding: 72pt 72pt 72pt 72pt
}

.c6 {
    font-weight: 700;
    font-family: "Times New Roman"
}

.c11 {
    padding: 0;
    margin: 0
}

.c7 {
    color: inherit;
    text-decoration: inherit
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    /* color: #666666; */
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 0pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}
