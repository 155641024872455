.suggestion {
  position: absolute !important;
  width: 100%;
  background-color: white !important;
  box-sizing: border-box !important;
  z-index: 2;
  border-radius: 30px;
  margin-top: -24px;
  padding: 50px 20px 26px 20px;
  box-shadow: 0 0 1px black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  .suggests-list {
    font-family: "Comfortaa", cursive;
    color: #1d77d3;
    font-size: 0.75rem;
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
  }
  b {
    font-size: 0.75rem;
    color: gray;
    text-transform: uppercase;
  }
  li {
    padding-left: 0;
    &:hover {
      cursor: pointer;
    }
  }
}
.MuiButton-label {
  color: #1d77d3;
  font-size: 0.75rem;
}
.clear-search {
  float: right;
}
.clear-search-div {
  width: 100%;
  height: 25px;
}
@media (max-width: 575.98px) {
  .suggestion {
    width: 100%;
    margin-top: -24px;
    padding: 50px 20px 26px 20px;
    .suggests-list {
      font-size: 0.75rem;
      max-height: 230px;
      width: 100%;
    }
    b {
      font-size: 0.55rem;
    }
    li {
      padding-left: 0;
    }
  }
  .MuiButton-label {
    color: #1d77d3;
    font-size: 0.65rem!important;
  }
  .clear-search {
    float: right;
    padding-top: 0!important;
  }
  .clear-search-div {
    width: 100%;
    height: 25px;
  }
}
