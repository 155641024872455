.QR-code {
  height: 100px;
  width: 100px;
  text-align: center;
  box-shadow: 0 0 3px 0px #587399;
  font-size: 1rem;
  margin: 10px;
  border-radius: 20px;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }

  .qrcode-icon {
    margin: 20px;
    opacity: 0.8;
  }
}

.QR-codes {
  position: relative;
}

.QR-code-page {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  z-index: 2;
  box-shadow: 0 0 1px black;
  margin: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-top: -30px;
  padding: 50px 20px;
  overflow-y: auto;
  font-family: "Comfortaa", cursive;
  color: rgb(132, 132, 151);
  font-size: 0.95rem;
  text-align: center;

  h4 {
    margin: 25px 0px 0px 0px;
    color: #587399;
    text-transform: capitalize;
    font-family: "Comfortaa", cursive;
  }

  p {
    margin: 7px 0px 25px 0px;
  }

  li {
    padding: 0;
    width: 100% !important;
    border-radius: 20px;
  }
}

b,
a {
  text-decoration-line: none;
  color: #1D77D3;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-family: "Comfortaa", cursive;
}

.MuiButton-label {
  color: #1D77D3;
  font-size: 0.75rem;
}
